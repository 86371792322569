.video-editing-container{
    max-width: 1200px;
    margin: 0 auto;
    color: aliceblue;
}

.VideoEditing-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .VideoEditing-banner-content {
    flex: 1;
    max-width: 50%;
    padding: 20px;
    color: #fff; /* Change text color to white */
  }
  
  .VideoEditing-banner-image {
    flex: 1;
    max-width: 50%;
    padding: 20px;
  }
  
  .VideoEditing-banner-heading {
    margin-top: 0;
    text-align: center;
    font-size: 2.5rem; /* Increase heading font size */
  }
  
  .VideoEditing-banner-content p {
    margin-bottom: 20px;
    text-align: justify;
  }
  
  .VideoEditing-banner-content button {
    padding: 12px 24px; /* Increase padding */
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px; /* Increase font size */
    display: block;
    margin: 0 auto;
    transition: background-color 0.3s; /* Add smooth transition effect */
  }
  
  .VideoEditing-banner-content button:hover {
    background-color: #0056b3; /* Darken button color on hover */
  }
  
  .VideoEditing-banner-image img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 5px;

  }
  
  body {
    background-color: black;
  }
  
  @media (max-width: 768px) {
    .VideoEditing-banner {
      flex-direction: column;
    }
  
    .VideoEditing-banner-content, .VideoEditing-banner-image {
      max-width: 100%;
    }
  }
  






  .VideoEditing-service-overview {
    max-width: 1200px;
    margin: 0 auto;
    color: aliceblue;
    padding: 20px;
  }
  
  .VideoEditing-service-overview-banner {
    display: flex;
    flex-direction: row-reverse; /* Reversed direction to have content on the right */
    align-items: center;
  }
  
  .VideoEditing-service-overview-banner-content {
    flex: 1;
  }
  
  .VideoEditing-service-overview-banner-heading {
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .VideoEditing-service-overview-banner-content p {
    margin-bottom: 20px;
    text-align: justify;
  }
  
  .VideoEditing-service-overview-banner-image {
    flex: 1;
  }
  
  .VideoEditing-service-overview-banner-image img {
    width: 100%;
    height: auto;
  }
  
  .VideoEditing-service-overview-banner-content button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    display: block;
    margin: 0 auto;
  }
  
  /* Responsive Styling */
  @media (max-width: 768px) {
    .VideoEditing-service-overview-banner {
      flex-direction: column;
      text-align: center; /* Center content in mobile view */
    }
  
    .VideoEditing-service-overview-banner-content,
    .VideoEditing-service-overview-banner-image {
      width: 100%;
    }
  
    .VideoEditing-service-overview-banner-content {
      padding: 20px 0; /* Adjust padding in mobile view */
    }
  }

  






  .VideoEditing-platform-info {
    margin: 20px;
    display: flex;
    flex-wrap: wrap; /* Wrap items onto multiple lines */
    justify-content: space-between; /* Distribute items evenly */
  }
  
  .VideoEditing-platform-info-title {
    width: 100%;
    text-align: center;
    margin-bottom: 20px; /* Add space below the title */
  }
  
  .VideoEditing-platform-info-item {
    flex: 0 1 calc(30% - 20px); /* Set width for three items per row with spacing */
    display: flex;
    flex-direction: column; /* Arrange children vertically */
    align-items: center; /* Center items horizontally */
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px; /* Add space between items */
  }
  
  .VideoEditing-platform-info-logo {
    margin-bottom: 10px; /* Add space between logo and platform name */
    width: 100px; /* Adjust width as needed */
    height: 100px; /* Adjust height as needed */
    flex-shrink: 0; /* Prevent shrinking */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .VideoEditing-platform-info-logo img {
    width: 80px; /* Adjust size as needed */
    height: 80px; /* Adjust size as needed */
    object-fit: contain; /* Maintain aspect ratio */
  }
  
  .VideoEditing-platform-info-heading {
    margin-top: 0;
    text-align: center; /* Center platform name */
  }
  
  .VideoEditing-platform-info-description {
    color: #666;
    text-align: justify; /* Justify description text */
  }
  
  @media (max-width: 768px) {
    .VideoEditing-platform-info-item {
      flex-basis: calc(100% - 20px); /* Set width for two items per row on tablets with spacing */
    }
  }
  
  @media (max-width: 576px) {
    .VideoEditing-platform-info-item {
      flex-basis: calc(100%); /* Set width for one item per row on mobile phones with spacing */
    }
  }

  



  .VideoEditing-expertise-title {
    text-align: center;
    margin-bottom: 30px;
    color: #ffffff; /* Change text color to white */
  }
  
  .VideoEditing-expertise-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .VideoEditing-expertise-item {
    flex: 0 1 calc(33.33% - 20px); /* Two items per row with spacing */
    padding: 20px;
    margin: 10px; /* Adjusted margin for better spacing */
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #000000;
    margin-bottom: 20px; /* Add spacing between expertise items */
    transition: transform 0.3s ease;
  }
  
  .VideoEditing-expertise-item:hover {
    transform: translateY(-5px); /* Add a hover effect */
  }
  
  .VideoEditing-expertise-heading {
    margin-top: 0;
    color: #ffffff; /* Change heading color to white */
  }
  
  .VideoEditing-expertise-description {
    color: #ffffff; /* Change description color to white */
  }
  
  /* Media query for mobile devices */
  @media (max-width: 768px) {
    .VideoEditing-expertise-item {
      flex: 1 1 calc(100% - 20px); /* One item per row with spacing */
    }
  }
  






  .VideoEditing-client-testimonials {
    padding: 20px;
  }
  
  .VideoEditing-client-testimonials-title {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .VideoEditing-testimonial-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .VideoEditing-testimonial {
    flex: 0 1 calc(100%); /* Two testimonials per row with spacing */
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #000000;
    margin-bottom: 20px; /* Add spacing between testimonials */
  }
  
  .VideoEditing-testimonial-text {
    margin-bottom: 10px;
  }
  
  .VideoEditing-testimonial-author {
    margin: 0;
    font-style: italic;
  }






  .VideoEditing-call-to-action {
    background-color: #000000;
    padding: 10px 0;
}

.VideoEditing-cta-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
}

.VideoEditing-cta-content {
    flex: 0 0 50%;
    padding: 0 20px;
    text-align: center;
}

.VideoEditing-cta-content h2 {
    margin-bottom: 20px;
}

.VideoEditing-cta-content p {
    margin-bottom: 30px;
}

.VideoEditing-cta-content button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}

.VideoEditing-cta-image {
    flex: 0 0 50%;
}

.VideoEditing-cta-image img {
    max-width: 100%;
    height: auto;
    display: block;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .VideoEditing-cta-container {
        flex-direction: column;
        text-align: center;
    }

    .VideoEditing-cta-content,
    .VideoEditing-cta-image {
        flex: 1 1 auto;
        width: 100%;
    }
}
