/* Base styles */
.navbar {
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  top: 0; /* Align to the top of the screen */
  left: 0; /* Align to the left of the screen */
  right: 0; /* Align to the right of the screen */
  z-index: 999;
  transition: height 0.3s ease; /* Smooth height transition */
  position: relative;
}

.navbar-logo {
  font-size: 24px;
  font-weight: bold;
  align-items: center;
}

.navbar-logo img{
  font-size: 24px;
  height: 150px;
  font-weight: bold;
  align-items: center;
}

.navbar-name {
  font-size: 58px;
  align-items: center;
}

.navbar-links {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px; /* Add padding for a professional look */
}

.navbar-links ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent text wrapping */
}

.navbar-links ul li {
  margin-left: 20px;
  display: flex;
  flex-direction: column; /* Display icon above name */
  align-items: center; /* Center icon and name */
}

.navbar-links ul li a {
  color: white;
  text-decoration: none;
  padding: 10px; /* Add padding to each option */
  display: flex;
  flex-direction: column;
  align-items: center;
}


.navbar-links ul li.active {
  background-color: rgba(255, 255, 255, 0.1); /* Background color for active option */
  color: black; /* Text color for active option */
  border-radius: 100%; /* Apply circular border to active option */
  width: 80px; /* Fixed width for the circle */
  height: 80px; /* Fixed height for the circle */
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-toggle {
  display: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  .brand-logo {
    display: none;
  }
  .navbar{
    display: flex;
    flex-direction: row;
  }

  .navbar-links {
    display: none;
    position: absolute;
    top: calc(100% + 10px); /* Adjust spacing between navbar and dropdown */
    left: 0;
    background-color: black;
    width: 100%;
    z-index: 100;
    padding-top: 10px; /* Add padding to top of options for mobile */
    max-height: 200px; /* Set maximum height for dropdown */
    overflow-y: auto; /* Add scrollbar if options exceed max height */
  }

  .navbar-toggle {
    display: block;
    cursor: pointer;
  }

  .navbar-links.show {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
    width: 100%;
    box-sizing: border-box; /* Include padding in total width */
    margin: 0;
    padding: 0;
  }
  
  .navbar-links.show ul {
    list-style-type: none;
    padding: 0;
    width: 100%;
    margin: 0;
  }

  .navbar-links.show ul li {
    text-align: center;
    padding: 10px 0;
    margin: 0;
    width: 100%;

  }

  .navbar-links.show ul li a {
    color: white;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;


  }

  .navbar-links.show ul li.active {
    background-color: rgba(255, 255, 255, 0.1); /* Background color for active option */
    color: black; /* Text color for active option */
    border-radius: 90%; /* Apply circular border to active option */
    width: 100px; /* Fixed width for the circle */
    height: 50px; /* Fixed height for the circle */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .navbar-logo img{
    font-size: 24px;
    height: 80px;
    font-weight: bold;
    align-items: center;
  }
}

