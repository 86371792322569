/* Services Container */
.services-container {
  max-width: 1250px;
  margin: 0 auto;
  color: aliceblue;

}

/* Top Section */
.top-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.left-section h2 {
  font-size: 24px;
  font-weight: bold;
}

.right-section img {
  width: 100px;
  margin-left: 10px;
}

/* Service Cards Section */
/* Service Cards Section */
.service-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Change auto-fill to auto-fit */
  gap: 20px;
  margin: 10px 100px; /* Add margin 100px on left and right sides */
  border-color: #958a8a;
  justify-content: center; /* Align cards container to the center */
}



/* Service Cards Section */
.service-card {
  background-color: #02040ee8; /* Darker background for cards */
  color: #ffffff; /* White text color */
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 0.1px solid #797979; /* Add sharp thin white color border line */
}


.service-card img {
  width: 100%;
  border-radius: 4px;
  margin-bottom: 10px;
}

.service-card h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 3px; /* Reduced margin bottom for the heading */
  margin-top: 0;

}

.service-card p {
  margin-bottom: 5px; /* Reduced margin bottom for the paragraph */
  color: #cccccc; /* Lighter text color */
  margin-top: 0;
}

.service-card button {
  background-color: #0c1b2b; /* Blue button color */
  color: #ffffff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  border: 0.1px solid #797979; /* Add sharp thin white color border line */
  margin-top: 15px;
}

/* Hover effect for cards */
.service-card:hover {
  transform: translateY(-5px);
  transition: transform 0.3s ease;
}

/* Responsive Design */
@media (max-width: 768px) {
  .top-section {
    flex-direction: column;
  }

  .right-section {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }

  .right-section img {
    margin: 0 10px;
  }

  .service-cards {
    grid-template-columns: 1fr; /* Single column layout for cards on smaller screens */
    margin: 10px; /* Reset margin for smaller screens */
  }

  
}
