/* Career Guidance Section */
.career-guidance {
    background-color: black; /* Set black background */
    text-align: center;
    max-width: 1200px;
    margin: 0 auto;
    color: aliceblue;
  }
  
  .career-guidance-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  
  .career-guidance-content {
    flex: 1;
    max-width: 50%;
    margin: 0 30px;
    height: 800px;
  }
  
  .career-guidance-title {
    font-size: 1.8rem;
    margin-bottom: 20px;
    margin-top: 25%;
    color: white; /* Set white text */
  }
  
  .career-guidance-text {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 30px;
    color: white; /* Set white text */
    text-align: justify;
  }
  
  .career-guidance-button {
    display: inline-block;
    background-color: white; /* White background */
    color: black; /* Black text */
    font-size: 1.2rem;
    padding: 10px 20px;
    border: 2px solid white; /* White border */
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s; /* Smooth transition */
  }
  
  .career-guidance-button:hover {
    background-color: rgba(255, 255, 255, 0.2); /* Semi-transparent white background on hover */
    color: white; /* White text on hover */
    border-color: white; /* White border on hover */
  }
  
  .career-guidance-image-container {
    flex: 1;
    max-width: 500px;
    margin: 0 30px;
    margin-top: 10px;
  }
  
  .career-guidance-image {
    max-width: 100%;
    width: 100%;
    height: 100%;
    margin-bottom: 10%;
    margin-top: 10%;
  }
  
  /* Media queries */
  @media only screen and (max-width: 768px) {
    .career-guidance-title {
      font-size: 2rem;
      margin-top: 10%;
    }
  
    .career-guidance-text {
      font-size: 1rem;
      margin-top: 10%;
    }
  
    .career-guidance-button {
      font-size: 1rem;
      padding: 8px 16px;
    }
  
    .career-guidance-container {
      flex-direction: column;
    }
  
    .career-guidance-content,
    .career-guidance-image-container {
      max-width: 80%;
      margin: 0;
      margin-top: 25px;
    }
  
    .career-guidance-image {
      max-width: 100%;
      width: 100%;
      height: 100%;
      margin-top: 10%;
      margin-bottom: 10%;
    }
  }
  