:root {
    --footer-background-color: #000;
    --footer-text-color: #fff;
    --footer-link-color: #fff;
  }
  
  .site-footer {
    background-color: var(--footer-background-color);
    color: var(--footer-text-color);
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .footer-container {
    display: flex;
    justify-content: space-between;
    padding: 0 10%;
    flex-wrap: wrap; /* Allows items to wrap on smaller screens */
  }
  
  .footer-section {
    display: flex;
    flex-direction: column;
    margin: 10px; /* Adds space between sections */
  }
  
  .footer-section h4 {
    margin-bottom: 20px;
  }
  
  .footer-section a {
    color: var(--footer-link-color);
    text-decoration: none;
    margin-bottom: 10px;
    transition: color 0.3s ease; /* Smooth color transition for hover */
  }
  
  .footer-section a:hover,
  .footer-section a:focus {
    color: #ccc; /* Lighten link color on hover/focus for better interaction */
  }
  
  .footer-section p {
    margin-bottom: 10px;
  }
  
  .social-links {
    display: flex;

    align-items: center;
    gap: 10px; /* Space between icons */
    margin-top: 20px; /* Space from the last text link */
  }
  
  .footer-bottom {
    text-align: center;
    margin-top: 30px;
    width: 100%; /* Ensure full width on small screens */
  }
  
  .footer-bottom p {
    margin-bottom: 10px;
  }
  
  input[type="email"] {
    padding: 10px;
    border: none;
    border-radius: 4px;
    width: 100%; /* Full width to match container */
    margin-bottom: 20px;
    /* Add a box-shadow or border if desired */
  }
  
  /* Responsive design adjustments */
  @media (max-width: 768px) {
    .footer-container {
      padding: 0 5%; /* Reduce padding on smaller screens */
    }
  
    .footer-section {
      align-items: center; /* Center content on small screens */
      margin: 10px 0; /* Add vertical margin for stacked layout */
    }
  
    .social-links {
      justify-content: center; /* Center social links on small screens */
    }
  }