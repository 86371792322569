.website-development-container {
  max-width: 1200px;
  margin: 0 auto;
  color: aliceblue;
}

.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.banner-content {
  flex: 1;
  max-width: 50%;
  padding: 20px;
  
}

.banner-image {
  flex: 1;
  max-width: 50%;
  padding: 20px;
}

.banner-heading {
  margin-top: 0;
  text-align: center;
}

.banner-content p {
  margin-bottom: 20px;
  text-align: justify;
}

.banner-content button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  display: block; /* Ensure the button behaves as a block element */
  margin: 0 auto; /* Center the button horizontally */
}


.banner-content button:hover {
  background-color: #0056b3;
}

.banner-image img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 5px;
}

body{
  background-color: black;
}

@media (max-width: 768px) {
  .banner {
    flex-direction: column;
  }

  .banner-content, .banner-image {
    max-width: 100%;
  }
}



.service-overview {
  /* margin-top: 50px; */
  max-width: 1200px;
  margin: 0 auto;
  color: aliceblue;
  padding: 20px;
}

.service-overview-banner {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.service-overview-banner-content {
  flex: 1;

}

.service-overview-banner-heading {
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: center;
}


.service-overview-banner-content  p {
  margin-bottom: 20px;
  text-align: justify;
}


.service-overview-banner-image {
  flex: 1;
}

.service-overview-banner-image img {
  width: 100%;
  height: auto;
}
.service-overview-banner-content button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  display: block; /* Ensure the button behaves as a block element */
  margin: 0 auto; /* Center the button horizontally */
}

/* Responsive Styling */
@media (max-width: 768px) {
  .service-overview-banner {
      flex-direction: column;
  }

  .service-overview-banner-content,
  .service-overview-banner-image {
      width: 100%;
  }

  .service-overview-banner-content {
      padding: 20px 0;
      text-align: center;
  }
}





.platform-info {
  margin: 20px;
  display: flex;
  flex-wrap: wrap; /* Wrap items onto multiple lines */
  justify-content: space-between; /* Distribute items evenly */
}

.platform-info-title {
  width: 100%;
  text-align: center;
  margin-bottom: 20px; /* Add space below the title */
}

.platform-info-item {
  flex: 0 1 calc(30% - 20px); /* Set width for three items per row with spacing */
  display: flex;
  flex-direction: column; /* Arrange children vertically */
  align-items: center; /* Center items horizontally */
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px; /* Add space between items */
}

.platform-info-logo {
  margin-bottom: 10px; /* Add space between logo and platform name */
  width: 100px; /* Adjust width as needed */
  height: 100px; /* Adjust height as needed */
  flex-shrink: 0; /* Prevent shrinking */
  display: flex;
  justify-content: center;
  align-items: center;
}

.platform-info-logo img {
  width: 80px; /* Adjust size as needed */
  height: 80px; /* Adjust size as needed */
  object-fit: contain; /* Maintain aspect ratio */
}

.platform-info-heading {
  margin-top: 0;
  text-align: center; /* Center platform name */
}

.platform-info-description {
  color: #666;
  text-align: justify; /* Justify description text */
}

@media (max-width: 768px) {
  .platform-info-item {
    flex-basis: calc(100% - 20px); /* Set width for two items per row on tablets with spacing */
  }
}

@media (max-width: 576px) {
  .platform-info-item {
    flex-basis: calc(100%); /* Set width for one item per row on mobile phones with spacing */
  }
}


.portfolio-showcase{
  text-align: center;
  font-size: 33px;
}
.portfolio-grid {
  
  display: flex;
  overflow-x: auto; /* Enable horizontal scrolling */
  scroll-snap-type: x mandatory; /* Snap scrolling to each image */
  -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
}

.portfolio-item {
  flex: 0 0 auto; /* Prevent items from shrinking */
  scroll-snap-align: start; /* Align each item to the start of the container */
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add box shadow for depth */
  margin-right: 20px; /* Add some space between items */
  transition: transform 0.3s ease; /* Add smooth transition effect */
  cursor: pointer; /* Change cursor to indicate interactivity */
}

.portfolio-item:hover {
  transform: translateY(-5px); /* Add slight lift on hover for interactive effect */
}

.portfolio-item img {
  border-radius: 10px 10px 0 0; /* Round the top corners */
  max-width: 60%; /* Ensure images don't exceed their container */
  height: auto; /* Maintain aspect ratio */
}

.portfolio-item-content {
  padding: 15px; /* Add padding for content inside the card */
}

.portfolio-item-heading {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.portfolio-item-description {
  margin: 10px 0 0;
  font-size: 14px;
  color: #666;
}



.expertise-title {
  text-align: center;
  margin-bottom: 30px;
}

.expertise-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.expertise-item {
  flex: 0 0 calc(33.33% - 20px); /* Three items per row with spacing */
  margin: 10px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #000000;
  transition: transform 0.3s ease;
}

.expertise-item:hover {
  transform: translateY(-5px); /* Add a hover effect */
}

.expertise-heading {
  margin-top: 0;
}

.expertise-description {
  color: #666;
}



.client-testimonials{
  padding: 20px;
}
.client-testimonials-title {
  text-align: center;
  margin-bottom: 30px;
}

.testimonial-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;

}

.testimonial {
  flex: 0 1 calc(100%); /* Two testimonials per row with spacing */
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #000000;
}

.testimonial-text {
  margin-bottom: 10px;
}

.testimonial-author {
  margin: 0;
  font-style: italic;
}




/* Default styles */
.call-to-action {
  background-color: #000000;
  padding: 50px 0;
}

.cta-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.cta-content {
  flex: 0 0 50%;
  padding: 0 20px;
  text-align: center;
}

.cta-content h2 {
  margin-bottom: 20px;
}

.cta-content p {
  margin-bottom: 30px;
}

.cta-content button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.cta-image {
  flex: 0 0 50%;
}

.cta-image img {
  max-width: 100%;
  height: auto;
  display: block;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .cta-container {
    flex-direction: column;
    text-align: center;
  }

  .cta-content,
  .cta-image {
    flex: 1 1 auto;
    width: 100%;
  }
}