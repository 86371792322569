.team-section {
    background-color: #000000;
    padding: 50px 0;
  }
  
  .team-title {
    color: #ffffff;
    text-align: center;
    margin-bottom: 30px;
    font-size: 24px;
  }
  
  .team-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .team-member {
    flex: 0 0 calc(25% - 20px); /* Display three members per row with spacing */
    margin: 10px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #000000;
    text-align: center;
    transition: transform 0.3s ease;
  }
  
  .team-member:hover {
    transform: translateY(-5px);
  }
  
  .member-image {
    width: 100px; /* Adjust as needed */
    height: 100px; /* Adjust as needed */

  }
  
  .member-name {
    font-size: 18px;
    margin-bottom: 10px;
    color: #fff2f2;

  }
  
  .member-position {
    font-style: italic;
    color: #fff2f2;
    margin-bottom: 10px;
  }
  
  .member-bio {
    color: #ffffff;
  }
  

  @media (max-width: 1000px) {
    .team-member {
      flex: 0 0 calc(40% - 20px); /* Display three members per row with spacing */
      margin: 10px;
      padding: 20px;
      border: 1px solid #ccc;
      border-radius: 5px;
      background-color: #000000;
      text-align: center;
      transition: transform 0.3s ease;
    }
    
  }

  @media (max-width: 600px) {
    .team-member {
      flex: 0 0 calc(80% - 20px); /* Display three members per row with spacing */
      margin: 10px;
      padding: 20px;
      border: 1px solid #ccc;
      border-radius: 5px;
      background-color: #000000;
      text-align: center;
      transition: transform 0.3s ease;
    }
    
  }