.performance-marketing-container {
  max-width: 1200px;
  margin: 0 auto;
  color: aliceblue;
}

.performance-marketing-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.performance-marketing-banner-content {
  flex: 1;
  max-width: 50%;
  padding: 20px;
}

.performance-marketing-banner-image {
  flex: 1;
  max-width: 50%;
  padding: 20px;
}

.performance-marketing-banner-heading {
  margin-top: 0;
  text-align: center;
}

.performance-marketing-banner-content p {
  margin-bottom: 20px;
  text-align: justify;
}

.performance-marketing-banner-content button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  display: block;
  margin: 0 auto;
}

.performance-marketing-banner-content button:hover {
  background-color: #0056b3;
}

.performance-marketing-banner-image img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 5px;
}

body {
  background-color: black;
}

@media (max-width: 768px) {
  .performance-marketing-banner {
    flex-direction: column;
  }

  .performance-marketing-banner-content, .performance-marketing-banner-image {
    max-width: 100%;
  }
}


.performance-marketing-platform-info {
    margin: 20px;
    display: flex;
    flex-wrap: wrap; /* Wrap items onto multiple lines */
    justify-content: space-between; /* Distribute items evenly */
  }
  
  .performance-marketing-platform-info-title {
    width: 100%;
    text-align: center;
    margin-bottom: 20px; /* Add space below the title */
  }
  
  .performance-marketing-platform-info-item {
    flex: 0 1 calc(30% - 20px); /* Set width for three items per row with spacing */
    display: flex;
    flex-direction: column; /* Arrange children vertically */
    align-items: center; /* Center items horizontally */
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px; /* Add space between items */
  }
  
  .performance-marketing-platform-info-logo {
    margin-bottom: 10px; /* Add space between logo and platform name */
    width: 100px; /* Adjust width as needed */
    height: 100px; /* Adjust height as needed */
    flex-shrink: 0; /* Prevent shrinking */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .performance-marketing-platform-info-logo img {
    width: 80px; /* Adjust size as needed */
    height: 80px; /* Adjust size as needed */
    object-fit: contain; /* Maintain aspect ratio */
  }
  
  .performance-marketing-platform-info-heading {
    margin-top: 0;
    text-align: center; /* Center platform name */
  }
  
  .performance-marketing-platform-info-description {
    color: #666;
    text-align: justify; /* Justify description text */
  }
  
  @media (max-width: 768px) {
    .performance-marketing-platform-info-item {
      flex-basis: calc(100% - 20px); /* Set width for two items per row on tablets with spacing */
    }
  }
  
  @media (max-width: 576px) {
    .performance-marketing-platform-info-item {
      flex-basis: calc(100%); /* Set width for one item per row on mobile phones with spacing */
    }
  }
  
  
  .performance-marketing-overview {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
  
  .performance-marketing-overview-content {
    flex: 1;
  }
  
  .performance-marketing-overview-heading {
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .performance-marketing-overview-content p {
    margin-bottom: 20px;
    text-align: justify;
  }
  
  .performance-marketing-overview-image {
    flex: 1;
  }
  
  .performance-marketing-overview-image img {
    width: 100%;
    height: auto;
  }
  
  .performance-marketing-overview-content button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    display: block;
    margin: 20px auto 0;
  }
  
  /* Responsive Styling */
  @media (max-width: 768px) {
    .performance-marketing-overview {
      flex-direction: column;
    }
  
    .performance-marketing-overview-content,
    .performance-marketing-overview-image {
      width: 100%;
    }
  
    .performance-marketing-overview-content {
      padding: 20px 0;
      text-align: center;
    }
  }
  

  .performance-marketing-expertise-title {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .performance-marketing-expertise-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .performance-marketing-expertise-item {
    flex: 0 1 calc(33.33% - 20px); /* Two items per row with spacing */
    padding: 20px;
    margin: 10px; /* Adjusted margin for better spacing */
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #000000;
    margin-bottom: 20px; /* Add spacing between expertise items */
  }
  
  .performance-marketing-expertise-item:hover {
    transform: translateY(-5px); /* Add a hover effect */
  }
  
  .performance-marketing-expertise-heading {
    margin-top: 0;
  }
  
  .performance-marketing-expertise-description {
    color: #ffffff;
  }
  
  /* Media query for mobile devices */
  @media (max-width: 768px) {
    .performance-marketing-expertise-item {
      flex: 1 1 calc(100% - 20px); /* One item per row with spacing */
    }
  }
  






  .performance-marketing-client-testimonials {
    padding: 20px;
  }
  
  .performance-marketing-client-testimonials-title {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .performance-marketing-testimonial-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .performance-marketing-testimonial {
    flex: 0 1 calc(100%); /* Two testimonials per row with spacing */
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #000000;
    margin-bottom: 20px; /* Add spacing between testimonials */
  }
  
  .performance-marketing-testimonial-text {
    margin-bottom: 10px;
  }
  
  .performance-marketing-testimonial-author {
    margin: 0;
    font-style: italic;
  }
  





  .performance-marketing-call-to-action {
    background-color: #000000;
    padding: 10px 0;
  }
  
  .performance-marketing-cta-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .performance-marketing-cta-content {
    flex: 0 0 50%;
    padding: 0 20px;
    text-align: center;
  }
  
  .performance-marketing-cta-content h2 {
    margin-bottom: 20px;
  }
  
  .performance-marketing-cta-content p {
    margin-bottom: 30px;
  }
  
  .performance-marketing-cta-content button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .performance-marketing-cta-image {
    flex: 0 0 50%;
  }
  
  .performance-marketing-cta-image img {
    max-width: 100%;
    height: auto;
    display: block;
  }
  
  /* Media query for mobile devices */
  @media (max-width: 768px) {
    .performance-marketing-cta-container {
      flex-direction: column;
      text-align: center;
    }
  
    .performance-marketing-cta-content,
    .performance-marketing-cta-image {
      flex: 1 1 auto;
      width: 100%;
    }
  }
  



  .performance-marketing-call-to-action-one {
    background-color: #000000;
    padding: 50px 0;
  }
  
  .performance-marketing-cta-container-one {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .performance-marketing-cta-content-one {
    flex: 0 0 50%;
    padding: 0 20px;
    text-align: center;
  }
  
  .performance-marketing-cta-content-one h2 {
    margin-bottom: 20px;
  }
  
  .performance-marketing-cta-content-one p {
    margin-bottom: 30px;
  }
  
  .performance-marketing-cta-content-one button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .performance-marketing-cta-image-one {
    flex: 0 0 50%;
  }
  
  .performance-marketing-cta-image-one img {
    max-width: 100%;
    height: auto;
    display: block;
  }
  
  /* Media query for mobile devices */
  @media (max-width: 768px) {
    .performance-marketing-cta-container-one {
      flex-direction: column-reverse    ;
      text-align: center;
    }
  
    .performance-marketing-cta-content-one,
    .performance-marketing-cta-image-one {
      flex: 1 1 auto;
      width: 100%;
    }
  }
  