.contact-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #000;
    color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  }
  
  .contact-header img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 20px;
    filter: brightness(0.7); /* Decrease brightness for glassy effect */
  }
  
  .contact-form {
    background-color: rgba(255, 255, 255, 0.1); /* Transparent white for form background */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  }
  
  .contact-form h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #fff;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: calc(100% - 40px); /* Adjust the width as needed */
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.2); /* Transparent white for input background */
    color: #fff;
  }
  
  .contact-form textarea {
    resize: vertical;
  }
  
  .contact-form button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .contact-form button:hover {
    background-color: #0056b3;
  }
  
  .contact-info {
    margin-top: 20px;
  }
  
  .contact-info h3 {
    margin-bottom: 10px;
    font-size: 20px;
    color: #fff;
  }
  
  .contact-info p {
    margin-bottom: 5px;
    color: #fff;
  }
  
  @media (max-width: 768px) {
    .contact-page {
      padding: 10px;
    }
  }
  