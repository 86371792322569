/* Styles for the homepage container */
.homepage-container {
  display: flex;
  flex-direction: column-reverse; /* Change flex direction to column for smaller devices */
  align-items: center;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: black;
  height: auto;
  max-width: 1200px;
  margin: 0 auto;
  color: aliceblue;
}

/* Styles for the left content */
.left-content {
  padding: 20px;
  text-align: left; /* Center align text for better readability */
}

.left-content h1, .left-content h2 {
  margin: 0; /* Remove default margin */
  color: white;
}

.left-content h1 {
  font-size: 24px;
  text-align: center;
}

.left-content h2 {
  font-size: 2rem;
  text-align: left;
  
}

/* Styles for the button */
.button {
  padding: 20px;
  margin: 20px;
  font-size: 28px;
  background-color: black; /* Set black background */
  color: white; /* Set white text */
  border: 2px solid rgba(255, 255, 255, 0.5); /* Add glass-like border */
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s; /* Add transition for smooth effect */
}

/* On hover, change button background and text color */
.button:hover {
  background-color: #007bff; /* Change background color on hover */
  color: white; /* Change text color on hover */
  border-color: rgba(255, 255, 255, 0.8); /* Change border color on hover */
}

/* Styles for the right content */
.right-content {
  width: 100%; /* Ensure image covers full width */
}

/* Styles for the banner image */
.banner img {
  width: 100%; /* Ensure image covers full width */
  height: auto;
}

/* Animation for changing text */
@keyframes textAnimation {
  0% { opacity: 0; transform: translateY(-10px); }
  100% { opacity: 1; transform: translateY(0); }
  
}

/* Apply text animation */
.text-animation {
  animation: textAnimation 0.5s ease-in-out; /* Adjust animation duration and easing */
  text-align: justify;
}

/* Media query for responsiveness */
@media screen and (min-width: 768px) {
  .homepage-container {
    flex-direction: row; /* Change flex direction back to row for larger devices */
  }
  .left-content {
    flex: 0.6; /* Adjust flex value for left content */
    text-align: left; /* Reset text alignment */
  }
  .right-content {
    flex: 1; /* Adjust flex value for right content */
  }
}
