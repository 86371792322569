.container {
  background-color: black;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* To center content vertically */
  max-height: 80%;
  max-width: 1200px;
  margin: 0 auto;
  color: aliceblue;
}

.heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  color: white;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px; /* Space between heading and content */
}

.image {
  width: 80%;
  height: auto; /* Automatically adjust height */
  margin-bottom: 20px; /* Space between image and text */
}

.textContainer {
  text-align: center;
}

.text {
  font-size: 18px;
  line-height: 1.5;
  color: white;
  text-align: justify;

}

.button {
  padding: 15px 30px;
  background-color: transparent;
  color: white;
  border: 2px solid white;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 20px; /* Space between text and button */
}

.button:hover {
  background-color: white;
  color: black;
}

/* Media Queries for responsiveness */
@media (min-width: 768px) {
  .container {
    padding: 40px;
  }

  .content {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 40px;
  }

  .image {
    flex: 1;
    text-align: center;
    width: 100%;
    margin-right: 40px; /* Space between image and text on larger screens */
    margin-bottom: 0; /* Remove bottom margin */
  }

  .textContainer {
    text-align: left;
    flex-basis: 60%;
    max-width: 60%;
  }
}

.video-editing-container{
  max-width: 1200px;
  margin: 0 auto;
  color: aliceblue;
}

.VideoEditing-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.VideoEditing-banner-content {
  flex: 1;
  max-width: 50%;
  padding: 20px;
  color: #fff; /* Change text color to white */
}

.VideoEditing-banner-image {
  flex: 1;
  max-width: 50%;
  padding: 20px;
}

.VideoEditing-banner-heading {
  margin-top: 0;
  text-align: center;
  font-size: 2.5rem; /* Increase heading font size */
}

.VideoEditing-banner-content p {
  margin-bottom: 20px;
  text-align: justify;
}

.VideoEditing-banner-content button {
  padding: 12px 24px; /* Increase padding */
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px; /* Increase font size */
  display: block;
  margin: 0 auto;
  transition: background-color 0.3s; /* Add smooth transition effect */
}

.VideoEditing-banner-content button:hover {
  background-color: #0056b3; /* Darken button color on hover */
}

.VideoEditing-banner-image img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 5px;

}

body {
  background-color: black;
}

@media (max-width: 768px) {
  .VideoEditing-banner {
    flex-direction: column;
  }

  .VideoEditing-banner-content, .VideoEditing-banner-image {
    max-width: 100%;
  }
}







.VideoEditing-service-overview {
  max-width: 1200px;
  margin: 0 auto;
  color: aliceblue;
  padding: 20px;
}

.VideoEditing-service-overview-banner {
  display: flex;
  flex-direction: row-reverse; /* Reversed direction to have content on the right */
  align-items: center;
}

.VideoEditing-service-overview-banner-content {
  flex: 1;
}

.VideoEditing-service-overview-banner-heading {
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: center;
}

.VideoEditing-service-overview-banner-content p {
  margin-bottom: 20px;
  text-align: justify;
}

.VideoEditing-service-overview-banner-image {
  flex: 1;
}

.VideoEditing-service-overview-banner-image img {
  width: 100%;
  height: auto;
}

.VideoEditing-service-overview-banner-content button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  display: block;
  margin: 0 auto;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .VideoEditing-service-overview-banner {
    flex-direction: column;
    text-align: center; /* Center content in mobile view */
  }

  .VideoEditing-service-overview-banner-content,
  .VideoEditing-service-overview-banner-image {
    width: 100%;
  }

  .VideoEditing-service-overview-banner-content {
    padding: 20px 0; /* Adjust padding in mobile view */
  }
}



.container img{
  max-width: fit-content;
  width: 100%;
}