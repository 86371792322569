.business-consultation-container{
    max-width: 1200px;
    margin: 0 auto;
    color: aliceblue;
}

.BusinessConsultation-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .BusinessConsultation-banner-content {
    flex: 1;
    max-width: 50%;
    padding: 20px;
  }
  
  .BusinessConsultation-banner-image {
    flex: 1;
    max-width: 50%;
    padding: 20px;
  }
  
  .BusinessConsultation-banner-heading {
    margin-top: 0;
    text-align: center;
  }
  
  .BusinessConsultation-banner-content p {
    margin-bottom: 20px;
    text-align: justify;
  }
  
  .BusinessConsultation-banner-content button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    display: block;
    margin: 0 auto;
  }
  
  .BusinessConsultation-banner-content button:hover {
    background-color: #0056b3;
  }
  
  .BusinessConsultation-banner-image img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 5px;
  }
  
  .BusinessConsultation-intro-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    color: aliceblue;
  }
  
  .BusinessConsultation-intro-content h2 {
    text-align: center;
  }
  
  .BusinessConsultation-intro-content p {
    text-align: justify;
  }
  
  body {
    background-color: black;
  }
  
  @media (max-width: 768px) {
    .BusinessConsultation-banner {
      flex-direction: column;
    }
  
    .BusinessConsultation-banner-content, .BusinessConsultation-banner-image {
      max-width: 100%;
    }
  }



  .business-consultation-overview {
    max-width: 1200px;
    margin: 0 auto;
    color: aliceblue;
    padding: 20px;
  }
  
  .business-consultation-overview-banner {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
  
  .business-consultation-overview-banner-content {
    flex: 1;
  }
  
  .business-consultation-overview-banner-heading {
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .business-consultation-overview-banner-content p {
    margin-bottom: 20px;
    text-align: justify;
  }
  
  .business-consultation-overview-banner-image {
    flex: 1;
  }
  
  .business-consultation-overview-banner-image img {
    width: 100%;
    height: auto;
  }
  
  .business-consultation-overview-banner-content button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    display: block;
    margin: 0 auto;
  }
  
  /* Responsive Styling */
  @media (max-width: 768px) {
    .business-consultation-overview-banner {
      flex-direction: column;
    }
  
    .business-consultation-overview-banner-content,
    .business-consultation-overview-banner-image {
      width: 100%;
    }
  
    .business-consultation-overview-banner-content {
      padding: 20px 0;
      text-align: center;
    }
  }






  .business-consultation-platform-info {
    margin: 20px;
    display: flex;
    flex-wrap: wrap; /* Wrap items onto multiple lines */
    justify-content: space-between; /* Distribute items evenly */
  }
  
  .business-consultation-platform-info-title {
    width: 100%;
    text-align: center;
    margin-bottom: 20px; /* Add space below the title */
  }
  
  .business-consultation-platform-info-item {
    flex: 0 1 calc(30% - 20px); /* Set width for three items per row with spacing */
    display: flex;
    flex-direction: column; /* Arrange children vertically */
    align-items: center; /* Center items horizontally */
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px; /* Add space between items */
  }
  
  .business-consultation-platform-info-logo {
    margin-bottom: 10px; /* Add space between logo and platform name */
    width: 100px; /* Adjust width as needed */
    height: 100px; /* Adjust height as needed */
    flex-shrink: 0; /* Prevent shrinking */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .business-consultation-platform-info-logo img {
    width: 80px; /* Adjust size as needed */
    height: 80px; /* Adjust size as needed */
    object-fit: contain; /* Maintain aspect ratio */
  }
  
  .business-consultation-platform-info-heading {
    margin-top: 0;
    text-align: center; /* Center platform name */
  }
  
  .business-consultation-platform-info-description {
    color: #666;
    text-align: justify; /* Justify description text */
  }
  
  @media (max-width: 768px) {
    .business-consultation-platform-info-item {
      flex-basis: calc(50% - 20px); /* Set width for two items per row on tablets with spacing */
    }
  }
  
  @media (max-width: 576px) {
    .business-consultation-platform-info-item {
      flex-basis: calc(100% - 20px); /* Set width for one item per row on mobile phones with spacing */
    }
  }
  


  /* .consultation-team {
    margin-top: 50px;
  }
  
  .team-members {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .team-member {
    max-width: 300px;
    height: 300px;
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .team-member h3 {
    margin-top: 0;
  }
  
  .team-member p {
    margin: 0px 0;
  }
   */


.business-consultation-expertise-title {
  text-align: center;
  margin-bottom: 30px;
  color: #ffffff;
  font-size: 24px;
}

.business-consultation-expertise-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.business-consultation-expertise-item {
  flex: 0 0 calc(33.33% - 20px); /* Two items per row with spacing */
  margin: 10px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #000000;
  transition: transform 0.3s ease;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
}

.business-consultation-expertise-item:hover {
  transform: translateY(-5px); /* Add a hover effect */
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2); /* Enhance the box shadow on hover */
}

.business-consultation-expertise-heading {
  margin-top: 0;
  color: #ffffff;
  font-size: 20px;
}

.business-consultation-expertise-description {
  color: #ffffff;
  font-size: 16px;
  text-align: justify;
}

/* Responsive Styling */
@media (max-width: 600px) {
  .business-consultation-expertise-item {
    flex: 1 1 calc(100% - 20px); /* One item per row with spacing */
  }
}



.business-consultation-client-testimonials {
    padding: 10px;
  }
  
  .business-consultation-client-testimonials-title {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .business-consultation-testimonial-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px;
  }
  
  .business-consultation-testimonial {
    flex: 0 1 calc(100%); /* Two testimonials per row with spacing */
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #000000;
    margin-bottom: 20px; /* Add spacing between testimonials */
  }
  
  .business-consultation-testimonial-text {
    margin-bottom: 10px;
  }
  
  .business-consultation-testimonial-author {
    margin: 0;
    font-style: italic;
  }
  





/* Default styles */
.business-consultation-call-to-action {
    background-color: #000000;
    padding: 50px 0;
  }
  
  .business-consultation-cta-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .business-consultation-cta-content {
    flex: 0 0 50%;
    padding: 0 20px;
    text-align: center;
  }
  
  .business-consultation-cta-content h2 {
    margin-bottom: 20px;
    color: #ffffff; /* Change text color to white */
  }
  
  .business-consultation-cta-content p {
    margin-bottom: 30px;
    color: #ffffff; /* Change text color to white */
  }
  
  .business-consultation-cta-content button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .business-consultation-cta-image {
    flex: 0 0 50%;
  }
  
  .business-consultation-cta-image img {
    max-width: 100%;
    height: auto;
    display: block;
  }
  
  /* Media query for mobile devices */
  @media (max-width: 768px) {
    .business-consultation-cta-container {
      flex-direction: column-reverse;
      text-align: center;
    }
  
    .business-consultation-cta-content,
    .business-consultation-cta-image {
      flex: 1 1 auto;
      width: 100%;
    }
  }
  