.graphics-design-container {
    max-width: 1200px;
    margin: 0 auto;
    color: aliceblue;
  }
  
  .graphics-designing-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .graphics-designing-banner-content {
    flex: 1;
    max-width: 50%;
    padding: 20px;
    color: #fff; /* Change text color to white */
  }
  
  .graphics-designing-banner-image {
    flex: 1;
    max-width: 50%;
    padding: 20px;
  }
  
  .graphics-designing-banner-heading {
    margin-top: 0;
    text-align: center;
    font-size: 2rem; /* Increase heading font size */
  }
  
  .graphics-designing-banner-content p {
    margin-bottom: 20px;
    text-align: justify;
  }
  
  .graphics-designing-banner-content button {
    padding: 12px 24px; /* Increase padding */
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px; /* Increase font size */
    display: block;
    margin: 0 auto;
    transition: background-color 0.3s; /* Add smooth transition effect */
  }
  
  .graphics-designing-banner-content button:hover {
    background-color: #0056b3; /* Darken button color on hover */
  }
  
  .graphics-designing-banner-image img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 5px;
  }
  
  body {
    background-color: black;
  }
  
  @media (max-width: 768px) {
    .graphics-designing-banner {
      flex-direction: column;
    }
  
    .graphics-designing-banner-content, .graphics-designing-banner-image {
      max-width: 100%;
    }
  }




  .graphics-designing-service-overview {
    max-width: 1200px;
    margin: 0 auto;
    color: aliceblue;
}

.graphics-designing-service-overview-banner {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.graphics-designing-service-overview-banner-content {
    flex: 1;
    max-width: 50%;
    padding: 20px;
    color: #fff;
}

.graphics-designing-service-overview-banner-image {
    flex: 1;
    max-width: 50%;
    padding: 20px;
}

.graphics-designing-service-overview-banner-heading {
    margin-top: 0;
    text-align: center;
    font-size: 2rem;
}

.graphics-designing-service-overview-banner-content p {
    margin-bottom: 20px;
    text-align: justify;
}

.graphics-designing-service-overview-banner-content button {
    padding: 12px 24px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    display: block;
    margin: 0 auto;
    transition: background-color 0.3s;
}

.graphics-designing-service-overview-banner-content button:hover {
    background-color: #0056b3;
}

.graphics-designing-service-overview-banner-image img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 5px;
}

body {
    background-color: black;
}

@media (max-width: 768px) {
    .graphics-designing-service-overview-banner {
        flex-direction: column;
    }

    .graphics-designing-service-overview-banner-content, .graphics-designing-service-overview-banner-image {
        max-width: 100%;
    }
}




.graphics-designing-platform-info {
    margin: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.graphics-designing-platform-info-title {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    color: #fff; /* Change text color to white */
}

.graphics-designing-platform-info-item {
    flex: 0 1 calc(30% - 20px);
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
    background-color: #000000; /* Change background color to black */
}

.graphics-designing-platform-info-logo {
    margin-bottom: 10px;
    width: 100px;
    height: 100px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.graphics-designing-platform-info-logo img {
    width: 80px;
    height: 80px;
    object-fit: contain;
}

.graphics-designing-platform-info-heading {
    margin-top: 0;
    text-align: center;
}

.graphics-designing-platform-info-description {
    color: #666;
    text-align: justify;
}

@media (max-width: 768px) {
    .graphics-designing-platform-info-item {
        flex-basis: calc(100% - 20px);
    }
}

@media (max-width: 576px) {
    .graphics-designing-platform-info-item {
        flex-basis: calc(100%);
    }
}



.graphics-designing-expertise {
    margin: 20px;
}

.graphics-designing-expertise-title {
    text-align: center;
    margin-bottom: 30px;
}

.graphics-designing-expertise-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.graphics-designing-expertise-item {
    flex: 0 0 calc(33.33% - 20px); /* Three items per row with spacing */
    margin: 10px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #000000;
    transition: transform 0.3s ease;
}

.graphics-designing-expertise-item:hover {
    transform: translateY(-5px); /* Add a hover effect */
}

.graphics-designing-expertise-heading {
    margin-top: 0;
}

.graphics-designing-expertise-description {
    color: #666;
}

/* Media query for tablets and smaller devices */
@media (max-width: 992px) {
    .graphics-designing-expertise-item {
        flex: 0 0 calc(50% - 20px); /* Two items per row with spacing */
    }
}

/* Media query for mobile devices */
@media (max-width: 576px) {
    .graphics-designing-expertise-item {
        flex: 1 1 calc(100% - 20px); /* One item per row with spacing */
    }
}


.graphics-designing-client-testimonials {
    padding: 20px;
}

.graphics-designing-client-testimonials-title {
    text-align: center;
    margin-bottom: 30px;
}

.graphics-designing-testimonial-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.graphics-designing-testimonial {
    flex: 0 1 calc(33.33% - 20px); /* Three testimonials per row with spacing */
    padding: 20px;
    margin: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #000000;
    margin-bottom: 20px; /* Add spacing between testimonials */
    transition: transform 0.3s ease; /* Move transition property here */
}

.graphics-designing-testimonial:hover {
    transform: translateY(-5px); /* Add a hover effect */
}

.graphics-designing-testimonial-text {
    margin-bottom: 10px;
}

.graphics-designing-testimonial-author {
    margin: 0;
    font-style: italic;
}

/* Media query for tablets and smaller devices */
@media (max-width: 992px) {
    .graphics-designing-testimonial {
        flex: 0 1 calc(50% - 20px); /* Two testimonials per row with spacing */
    }
}

/* Media query for mobile devices */
@media (max-width: 576px) {
    .graphics-designing-testimonial {
        flex: 1 1 calc(100% - 20px); /* One testimonial per row with spacing */
    }
}









.graphics-designing-call-to-action {
    background-color: #000000;
    padding: 10px 0;
}

.graphics-designing-cta-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
}

.graphics-designing-cta-content {
    flex: 0 0 50%;
    padding: 0 20px;
    text-align: center;
}

.graphics-designing-cta-content h2 {
    margin-bottom: 20px;
}

.graphics-designing-cta-content p {
    margin-bottom: 30px;
}

.graphics-designing-cta-content button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}

.graphics-designing-cta-content button:hover {
    background-color: #0056b3;
}

.graphics-designing-cta-image {
    flex: 0 0 50%;
}

.graphics-designing-cta-image img {
    max-width: 100%;
    height: auto;
    display: block;
}
