.lead-generation-container{
    max-width: 1200px;
  margin: 0 auto;
  color: aliceblue;
}

.lead-generation-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: #000000; /* Set background color */
  }
  
  .lead-generation-banner-content {
    flex: 1;
    max-width: 50%;
    padding: 20px;
    color: #ffffff; /* Set text color to white */
  }
  
  .lead-generation-banner-heading {
    margin-top: 0;
    text-align: center;
    font-size: 2.5rem; /* Increase heading font size */
  }
  
  .lead-generation-banner-content p {
    margin-bottom: 20px;
    text-align: justify;
  }
  
  .lead-generation-banner-content button {
    padding: 12px 24px; /* Increase padding */
    background-color: #007bff; /* Set button background color */
    color: #fff; /* Set button text color to white */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px; /* Increase font size */
    display: block;
    margin: 0 auto; /* Center the button horizontally */
    transition: background-color 0.3s; /* Add smooth transition effect */
  }
  
  .lead-generation-banner-content button:hover {
    background-color: #0056b3; /* Darken button color on hover */
  }
  
  .lead-generation-banner-image {
    flex: 1;
    max-width: 50%;
    padding: 20px;
  }
  
  .lead-generation-banner-image img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 5px;
  }
  





  .lead-generation-service-overview {
    /* Your styles for the service overview section */
    max-width: 1200px;
    margin: 0 auto;
    color: aliceblue;
    padding: 20px;
  }
  
  .lead-generation-service-overview-banner {
    /* Your styles for the banner container */
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
  
  .lead-generation-service-overview-banner-content {
    /* Your styles for the banner content */
    flex: 1;
  }
  
  .lead-generation-service-overview-banner-heading {
    /* Your styles for the banner heading */
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .lead-generation-service-overview-banner-content p {
    /* Your styles for the banner paragraph */
    margin-bottom: 20px;
    text-align: justify;
  }
  
  .lead-generation-service-overview-banner-image {
    /* Your styles for the banner image container */
    flex: 1;
  }
  
  .lead-generation-service-overview-banner-image img {
    /* Your styles for the banner image */
    width: 100%;
    height: auto;
  }
  
  .lead-generation-service-overview-banner-content button {
    /* Your styles for the banner button */
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    display: block;
    margin: 0 auto; /* Center the button horizontally */
  }
  
  /* Responsive Styling */
  @media (max-width: 768px) {
    .lead-generation-service-overview-banner {
      /* Your styles for responsive banner layout */
      flex-direction: column;
    }
  
    .lead-generation-service-overview-banner-content,
    .lead-generation-service-overview-banner-image {
      /* Your styles for responsive content and image */
      width: 100%;
    }
  
    .lead-generation-service-overview-banner-content {
      /* Your styles for responsive content */
      padding: 20px 0;
      text-align: center;
    }
  }

  

  .lead-generation-platform-info {
    margin: 20px;
    display: flex;
    flex-wrap: wrap; /* Wrap items onto multiple lines */
    justify-content: space-between; /* Distribute items evenly */
  }
  
  .lead-generation-platform-info-title {
    width: 100%;
    text-align: center;
    margin-bottom: 20px; /* Add space below the title */
  }
  
  .lead-generation-platform-info-item {
    flex: 0 1 calc(30% - 20px); /* Set width for three items per row with spacing */
    display: flex;
    flex-direction: column; /* Arrange children vertically */
    align-items: center; /* Center items horizontally */
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px; /* Add space between items */
  }
  
  .lead-generation-platform-info-logo {
    margin-bottom: 10px; /* Add space between logo and platform name */
    width: 100px; /* Adjust width as needed */
    height: 100px; /* Adjust height as needed */
    flex-shrink: 0; /* Prevent shrinking */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .lead-generation-platform-info-logo img {
    width: 80px; /* Adjust size as needed */
    height: 80px; /* Adjust size as needed */
    object-fit: contain; /* Maintain aspect ratio */
  }
  
  .lead-generation-platform-info-heading {
    margin-top: 0;
    text-align: center; /* Center platform name */
  }
  
  .lead-generation-platform-info-description {
    color: #666;
    text-align: justify; /* Justify description text */
  }
  
  @media (max-width: 768px) {
    .lead-generation-platform-info-item {
      flex-basis: calc(100% - 20px); /* Set width for two items per row on tablets with spacing */
    }
  }
  
  @media (max-width: 576px) {
    .lead-generation-platform-info-item {
      flex-basis: calc(100%); /* Set width for one item per row on mobile phones with spacing */
    }
  }
  


  .lead-generation-expertise-title {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .lead-generation-expertise-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .lead-generation-expertise-item {
    flex: 0 0 calc(33.33% - 20px); /* Three items per row with spacing */
    margin: 10px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #000000;
    transition: transform 0.3s ease;
  }
  
  .lead-generation-expertise-item:hover {
    transform: translateY(-5px); /* Add a hover effect */
  }
  
  .lead-generation-expertise-heading {
    margin-top: 0;
  }
  
  .lead-generation-expertise-description {
    color: #666;
  }

  


  .lead-generation-client-testimonials {
    padding: 20px;
  }
  
  .lead-generation-client-testimonials-title {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .lead-generation-testimonial-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px;
  }
  
  .lead-generation-testimonial {
    flex: 0 1 calc(100%); /* Two testimonials per row with spacing */
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #000000;
  }
  
  .lead-generation-testimonial-text {
    margin-bottom: 10px;
  }
  
  .lead-generation-testimonial-author {
    margin: 0;
    font-style: italic;
  }




/* Updated styles for lead generation */

.lead-generation-call-to-action {
    background-color: #000000;
    padding: 50px 0;
  }
  
  .lead-generation-cta-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .lead-generation-cta-content {
    flex: 0 0 50%;
    padding: 0 20px;
    text-align: center;
  }
  
  .lead-generation-cta-content h2 {
    margin-bottom: 20px;
  }
  
  .lead-generation-cta-content p {
    margin-bottom: 30px;
  }
  
  .lead-generation-cta-content button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .lead-generation-cta-image {
    flex: 0 0 50%;
  }
  
  .lead-generation-cta-image img {
    max-width: 100%;
    height: auto;
    display: block;
  }
  
  /* Media query for mobile devices */
  @media (max-width: 768px) {
    .lead-generation-cta-container {
      flex-direction: column;
      text-align: center;
    }
  
    .lead-generation-cta-content,
    .lead-generation-cta-image {
      flex: 1 1 auto;
      width: 100%;
    }
  }
  