/* About Section */
.about-container {
  background-color: black; /* Set black background */
  color: white; /* Set white text */
  padding: 50px;
  text-align: center;
  margin-left: 20px; /* Add left margin */
  margin-right: 20px; /* Add right margin */
  max-width: 1400px;
  margin: 0 auto; /* Center the container horizontally */
}

  
  .about-header {
    margin-bottom: 50px;
  }
  
  .about-heading {
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
  
  .about-tagline {
    font-size: 24px;
    font-weight: 500;
  }
  
  .about-founder {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
  }
  
  .about-founder-image {
    width: 300px;
    height: 300px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.2); /* Change shadow color to white */
  }
  
  .about-founder-name {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  
  .about-founder-title {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  
  .about-founder-description {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;
    text-align: justify;
    margin: 10px auto;
  }
  
  .about-cards {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-around;
    align-items: stretch;
    margin-bottom: 50px;
  }
  
  .about-card {
    flex-basis: calc(33.33% - 20px);
    margin-right: 0px;
    margin-bottom: 20px;
    background-color: #000000; /* Set dark background color */
    overflow: hidden;
  }
  

  
  .about-card-title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  
  .about-card-description {
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5;
    margin: 15px;
    text-align: justify;
  }
  
  .about-card-list {
    list-style: none;
    padding: 0;
    margin: 20px;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5;
    text-align: justify;
  }
  
  .about-card-list li {
    margin-bottom: 10px;
  }
  
  .about-animation {
    position: relative;
    overflow: hidden;
    margin-bottom: 50px;
  }
  
  .about-animation-image {
    width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: about-animation 10s linear infinite;
  }
  
  @keyframes about-animation {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  
  /* Media queries */
  @media (max-width: 768px) {
    .about-container {
      padding: 30px;
    }
  
    .about-heading {
      font-size: 28px;
      margin-bottom: 5px;
    }
  
    .about-tagline {
      font-size: 18px;
    }
  
    .about-founder-image {
      width: 200px;
      height: 200px;
      margin-bottom: 10px;
    }
  
    .about-founder-name {
      font-size: 28px;
      margin-bottom: 5px;
    }
  
    .about-founder-title {
      font-size: 20px;
      margin-bottom: 10px;
    }
  
    .about-founder-description {
      font-size: 16px;
      text-align: justify;
    }
  
    .about-card {
      flex-basis: 100%;
      margin-right: 0;
    }
    .about-cards {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-around;
      align-items: stretch;
      margin-bottom: 50px;
    }
  }
  