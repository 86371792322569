.not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    position: relative;
  }
  
  .not-found-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: linear-gradient(0deg, rgba(20,20,20,1) 0%, rgba(20,20,20,1) 50%, rgba(0,0,0,1) 100%);
    animation: not-found-animation 8s ease-in-out infinite;
  }
  
  .not-found-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-align: center;
    z-index: 1;
  }
  
  .not-found-title {
    font-size: 10rem;
    margin: 0;
    animation-name: not-found-title-animation;
    animation-duration: 1.5s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
    text-shadow: 2px 2px #000;
  }
  
  .not-found-subtitle {
    font-size: 2rem;
    margin-top: 2rem;
    text-shadow: 1px 1px #000;
  }
  
  @media (max-width: 768px) {
    .not-found-title {
      font-size: 6rem;
    }
    
    .not-found-subtitle {
      font-size: 1.5rem;
    }
  }
  
  @keyframes not-found-animation {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 100% 0;
    }
  }
  
  @keyframes not-found-title-animation {
    0% {
      transform: translateX(-10px) rotate(0deg);
    }
    50% {
      transform: translateX(10px) rotate(10deg);
    }
    100% {
      transform: translateX(-10px) rotate(0deg);
    }
  }
  